import React from "react";
import { Modal } from "react-bootstrap";

const FormatsModalModule = ({
  action: ActionComponent,
  actionProps,
  show,
  memo,
  loading,
  onHide,
  onConfirm,
}) => {
  return (
    <Modal show={show} onHide={loading ? null : onHide} centered>
      <Modal.Body className="p-4">
        {/* Render the dynamic action component */}
        {ActionComponent ? <ActionComponent {...actionProps} /> : null}

        {/* Display the memo message */}
        <div className="mt-3">
          <p className={loading ? "text-info" : "text-success"}>{memo}</p>
        </div>

        {/* Buttons for confirmation */}
        <div className="d-flex justify-content-between mt-auto pt-3">
          {/* Show "Yes" and "Cancel" buttons only if not loading and memo is not "Format Created" */}
          {!loading && memo !== "Format Created Successfully" && (
            <>
              <button
                className="btn btn-light border back-color-wite rounded-xx text-small"
                type="button"
                onClick={onHide}
              >
                Cancel
              </button>

              <button
                className="btn btn-primary border-none rounded-xx text-small text-nowrap px-1"
                type="button"
                onClick={onConfirm}
              >
                Yes
              </button>
            </>
          )}

          {/* Show "Close" button only if loading is false and format creation is successful */}
          {!loading && memo === "Format Created Successfully" && (
            <div className="d-flex justify-content-end w-100">
              <button
                className="btn btn-light border back-color-wite rounded-xx text-small"
                onClick={onHide}
              >
                Close
              </button>
            </div>
          )}

          {/* Show "Processing..." message when loading */}
          {loading && (
            <button
              className="btn btn-light border back-color-wite rounded-xx text-small w-100"
              disabled
            >
              Processing...
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FormatsModalModule;


